// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
	  font-family: Arial, sans-serif;
	    margin: 0;
	      padding: 0;
      }
.Carousel .d-block {
	  height: 400px;
	    object-fit: cover;
    }
      .App {
	        text-align: center;
		background-color: #6082B6;
	}

	.App-header {
		  background: #0096FF;
		    color: #FFF;
		      padding: 10px 0;
	      }

	      .App-section-ctrl-alt-repeat {
		      max-width: 800px;
			margin: 0 auto;
			padding: 20px;
			background-color: #6082B6;
		    }
	      .App-section-contact {
		      max-width: 800px;
			  margin: 0 auto;
			    padding: 20px;
			background-color: #6082B6;
			min-height: 50vh;
		    }
	      .App-section-header {
		      max-width: 800px;
			  margin: 0 auto;
			    padding: 20px;
			background-color: #6082B6;
		    }
  .App-footer {
    background: #333;
    color: #fff;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;GACG,8BAA8B;KAC5B,SAAS;OACP,UAAU;MACX;AACN;GACG,aAAa;KACX,iBAAiB;IAClB;MACE;SACG,kBAAkB;EACzB,yBAAyB;CAC1B;;CAEA;IACG,mBAAmB;MACjB,WAAW;QACT,eAAe;OAChB;;OAEA;QACC,gBAAgB;GACrB,cAAc;GACd,aAAa;GACb,yBAAyB;MACtB;OACC;QACC,gBAAgB;KACnB,cAAc;OACZ,aAAa;GACjB,yBAAyB;GACzB,gBAAgB;MACb;OACC;QACC,gBAAgB;KACnB,cAAc;OACZ,aAAa;GACjB,yBAAyB;MACtB;EACJ;IACE,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,eAAe;IACf,SAAS;IACT,WAAW;AACf","sourcesContent":["body {\n\t  font-family: Arial, sans-serif;\n\t    margin: 0;\n\t      padding: 0;\n      }\n.Carousel .d-block {\n\t  height: 400px;\n\t    object-fit: cover;\n    }\n      .App {\n\t        text-align: center;\n\t\tbackground-color: #6082B6;\n\t}\n\n\t.App-header {\n\t\t  background: #0096FF;\n\t\t    color: #FFF;\n\t\t      padding: 10px 0;\n\t      }\n\n\t      .App-section-ctrl-alt-repeat {\n\t\t      max-width: 800px;\n\t\t\tmargin: 0 auto;\n\t\t\tpadding: 20px;\n\t\t\tbackground-color: #6082B6;\n\t\t    }\n\t      .App-section-contact {\n\t\t      max-width: 800px;\n\t\t\t  margin: 0 auto;\n\t\t\t    padding: 20px;\n\t\t\tbackground-color: #6082B6;\n\t\t\tmin-height: 50vh;\n\t\t    }\n\t      .App-section-header {\n\t\t      max-width: 800px;\n\t\t\t  margin: 0 auto;\n\t\t\t    padding: 20px;\n\t\t\tbackground-color: #6082B6;\n\t\t    }\n  .App-footer {\n    background: #333;\n    color: #fff;\n    padding: 10px 0;\n    position: fixed;\n    bottom: 0;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
