import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './App.css';
import YouTube from 'react-youtube'; // Import the react-youtube package for embedding YouTube videos
import { Carousel } from 'react-responsive-carousel';

function App() {
	const youtubeVideoOptions = {
		width: '110%',
		height: '400',
		playerVars: {
			autoplay: 0,
		},
	};

	const screenshots = [
		'boulders.png',
		'bubbles.png',
		'championsroad.png',
		'gliding.png',
		'grinding.png',
		'sliding.png',
		'swinging.png',
		'wallrunning.png',
	];
	  return (
		      <div className="App">
		        <header className="App-header">
		          <h1>RDST Games LLC</h1>
		        </header>

		        <section className="App-section-header">
		          <h2>Welcome to RDST Games!</h2>
		        </section>

		        <section id="portfolio" className="App-section-ctrl-alt-repeat">
		          <h2>CTRL+ALT+REPEAT</h2>
		  	  <p> CTRL+ALT+REPEAT is a multiplayer 3D parkour platformer where you race against the clock and your friends. The game features 64 hand crafted levels across 4 worlds requiring patience and focus to complete. </p>
		          <p> Wishlist it now on <a href="https://store.steampowered.com/app/2652930/CTRLALTREPEAT">Steam</a>!</p>
		  	  <Carousel showThumbs={false}>
		  	    <div className="slide">
		             <YouTube videoId="e_ZYE2IE5ow" key={0} opts={youtubeVideoOptions} />	
		  	    </div>
		  	    {screenshots.map((URL, index) => (
			      <div className="slide">
			        <img alt={URL} src={URL} key={index+1} />
			      </div>
			      ))}
		          </Carousel>
		        </section>

		        <section id="contact" className="App-section-contact">
		          <h2>Contact Us</h2>
		  		<p>If you have any questions or inquiries, feel free to <a href="mailto:ctrlaltrepeatgame@gmail.com">email us</a>.</p>

		        </section>

		        <footer className="App-footer">
		          <div className="container">
		            <p>&copy; 2023 RDST Games LLC. All rights reserved.</p>
		          </div>
		        </footer>
		      </div>
		    );
}

export default App;

